<!--
 * @Author: a3802 253092329@qq.com
 * @Date: 2023-05-12 22:10:13
 * @LastEditors: a3802 253092329@qq.com
 * @LastEditTime: 2025-03-28 16:23:34
 * @FilePath: \tgvue\src\views\rule\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <van-nav-bar title="规则" left-text="" left-arrow fixed @click-left="onClickLeft" />
  <div class="rule_content">
    <div>以下权益均在成为「普宁短剧」 会员后享有:</div>
    <div>规则说明:</div>
    <div>解锁「普宁短剧」平台会员资格:本链接下支付相关金额为解锁「普宁短剧」平台会员费用，非商品直购费用，成功解锁「普宁短剧」平台会员即可参与抽现金红包活动。</div>
    <div>【中奖概率说明】</div>
    <div>4.注:部分携号转网号码可能导致充值不成功!</div>
    <div>5.本公司拥有对上述条款做最终解释的权利:</div>
    <div>口袋折扣优惠规则</div>
    <div>88红包中奖概率: 5%</div>
    <div>188红包中奖概率: 0.005%</div>
    <!-- <div>288红包中奖概率: 0.01%</div>
    <div>388红包中奖概率: 0.005%</div>
    <div>888红包中奖概率: 0.0001%</div> -->
    <div>生活权益大礼包: 94.885%</div>
    <div>“生活权益大礼包”内含：《菲住首住特惠》《国内游大礼包》《门票优惠券》《出境游大礼包》，生活权益大礼包兑换将下发到手机号对应账号，具体权益及相关使用规则以届时系统实际展示为准。</div>
    <div>
      3.本抽奖活动仅为支付解锁「普宁短剧」平台会员费用后使用，每位解锁会员用户仅限一次抽奖机会。
    </div>
    <div>4.奖励为福利资格型奖励，本活动均为用户自愿参与，用户抽中后可自由选取是否领取核销，在活动中获得奖品后，若未填写相关信息就退出活动页面，可能会导致奖品丢失，请在获得奖品后及时进行兑换或领取，避免损失。
    </div>
    <div>5.抽奖为付费会员福利，利用非正规途径刷取的用户一经发现将取消该福利并保留追溯的权利。</div>
    <div>6.若因用户个人行为(包括但不限于作弊、未填写相关领取信息等)导致未领取到奖品而产生的损失，与主办方无关。</div>
    <div>7.抽奖为会员福利，无需额外付费，本活动仅作为额外放送福利，最终解释权归本平台所有。</div>
    <div>8.如付费后不想参与该活动且未领取红包的，可联系客服进行退款。在活动期间退款、注销会员的，视同放弃参与该活动，主办方将收回平台会员权限。</div>
    <div>9.红包到账时效为中奖后3个工作日内，生活权益大礼包到账时效为领奖后3个工作日内下发到手机号对应账号。本活动的奖励红包一旦领取，视同已参与并认同活动规则，已购买会员权益生效，无法退款。</div>
    <div>10.我们保证，若您未领取红包权益，在购买产品后的3个月内有权单方取消订单并享受全额退款服务。超过此期限的，用户不享有单方取消订单及退款服务。</div>
    <div>客服电话:4006355773</div>
    <div>工作时间:09:00-21:00</div>
    <div>主办方（普宁市黛朵雯贸易有限公司）</div>
  </div>
</template>
<script>
export default {
  methods: {
    onClickLeft() {
      // 处理返回逻辑，例如调用 router.go(-1) 或 this.$router.back()
      this.$router.back(); // 假设你使用了 Vue Router
    },
  },
};
</script>
<style lang="less" scoped>
div {
  font-size: .4rem;
  color: #333;
  margin: 20px 20px;
}

/* 增加一个外部CSS类来指定标题栏标题的字体大小 */
/deep/ .van-nav-bar__text {
  font-size: .3rem;
  /* 修改为你想要的字体大小 */
}

/deep/ .van-nav-bar__title {
  font-size: .35rem;
}

/deep/ .van-nav-bar_content {
  height: .7rem;
  line-height: .7rem;
}

.van-nav-bar {
  margin: 0px;
}

/deep/ .van-badge__wrapper {
  font-size: 20px;
}

.rule_content {
  position: absolute;
  top: .6rem;
}
</style>
